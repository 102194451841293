

















































































































































































































import Confirmacao from '@/components/ui/Confirmacao.vue'
import RodapePersonalizado from '@/components/ui/data-tables/RodapePersonalizado.vue'
import DialogoDeDetalhesDaNota from '@/components/venda/DialogoDeDetalhesDaNota.vue'
import { CapaDaNota, ItemNotaFiscal, NotaFiscalCompleta } from '@/models'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import { aplicarMascaraParaCpfOculto, formatarCnpjOuCpf, formatarMoeda, removerFormatacaoDeCnpjOuCpf } from '@/shareds/formatadores'
import { maiorQueZero } from '@/shareds/regras-de-form'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { FindCapaDaNotaUseCase } from '@/usecases'
import IniciarUmaDevolucao from '@/usecases/venda/IniciarUmaDevolucao'
import Decimal from 'decimal.js'
import { Vue, Component, Ref } from 'vue-property-decorator'

@Component({
	components: {
		DialogoDeDetalhesDaNota,
		RodapePersonalizado,
		Confirmacao,
	},
})
export default class DialogoDeSelecaoDeDevolucaoDeSaida extends Vue {
	@Ref() checkboxDeSelecionarTodos!: HTMLInputElement

	maiorQueZero = maiorQueZero
	formatarMoeda = formatarMoeda

	mostrar = false
	buscando = false
	iniciandoDevolucao = false
	notasResumidas: CapaDaNota[] = []
	etapaDaDevolucao = 'escolhendo-nota'
	selecionados: ItemNotaFiscal[] = []
	notaSelecionada: NotaFiscalCompleta | null = null
	paginaSelecaoItens = 1
	filtroNF: string | null = null
	filtroSerie: string | null = null

	headersVendas = [
		{ text: 'Fornecedor/Origem', value: 'displayFornecedor', sortable: false },
		{ text: 'Número NF', value: 'nnf', sortable: false },
		{ text: 'Serie NF', value: 'serie', sortable: false },
		{ text: 'Valor', value: 'displayvnf', sortable: false },
		{ text: 'Data Emissão', value: 'diplayDataHora', sortable: false },
		{ text: 'Ações', value: 'actions', align: 'center' },
	]

	headersItensDaNota = [
		{
			text: 'Produto',
			value: 'xprod',
			class: 'text-no-wrap',
			width: '100%',
		},
		{
			text: 'Código',
			value: 'displayEans',
			class: 'text-no-wrap',
			align: 'right',
		},
		{ text: 'Quantidade', value: 'quantidade', class: 'text-no-wrap' },
		{
			text: 'Valor (R$)',
			value: 'vuncom',
			class: 'text-no-wrap',
			align: 'right',
		},
	]

	paginacao = {
		page: 0,
		itemsPerPage: 10,
		itemsLength: 0,
	}

	findNotaUseCase = new FindCapaDaNotaUseCase()
	iniciarDevolucao = new IniciarUmaDevolucao()

	selecionouTodos = false

	get vendaAtual() {
		return VendaModule.emissaoEntradaAtual
	}

	get notasFormatadas() {
		return this.notasResumidas.map(nota => ({
			...nota,
			displayFornecedor: this.displayFornecedor(nota),
			diplayDataHora: this.diplayDataHora(nota),
			displayvnf: this.displayvnf(nota),
		}))
	}

	get itensFormatados() {
		if (!this.notaSelecionada) return []

		return (
			this.notaSelecionada.det.filter(item => {
				return (Number(item.qcom) - item.quantidadeDevolvida) > 0
			})
				.map(item => ({
					...item,
					displayEans: item.cean,
					quantidadeSaldo: 1,
				})) || []
		)
	}

	displayvnf(nota: CapaDaNota) {
		return 'R$ ' + formatarMoeda(new Decimal(nota.vnf).toNumber())
	}

	diplayDataHora(nota: CapaDaNota) {
		if (!nota.dhEmi) return ''
		return dateTimeToPtBrFormat(nota.dhEmi)
	}

	displayFornecedor(nota: CapaDaNota) {
		const cnpjOuCpf = nota.cnpjEmit
		if (!cnpjOuCpf) return ''
		const cnpjOuCpfSemMascara = removerFormatacaoDeCnpjOuCpf(cnpjOuCpf)
		let display =
			cnpjOuCpfSemMascara.length <= 11
				? aplicarMascaraParaCpfOculto(cnpjOuCpfSemMascara)
				: formatarCnpjOuCpf(cnpjOuCpf)
		if (nota.nomeEmit) {
			display = display + ' - ' + nota.nomeEmit
		}
		return display
	}

	async buscarVendas() {
		if (!VendaModule.lojaDaEntrada) return

		let notasPaginadas

		try {
			this.buscando = true
			notasPaginadas = await this.findNotaUseCase.findNotasParaDevolucao({
				cnpjLoja: VendaModule.lojaDaEntrada.cnpj,
				cnpjEmissor: (this.vendaAtual?.cpfDoCliente as any).cnpjOuCpf||this.vendaAtual?.cpfDoCliente,
				numeroNF: !this.filtroNF ? undefined : this.filtroNF,
				serie: !this.filtroSerie ? undefined : this.filtroSerie,
				page: this.paginacao.page,
				size: this.paginacao.itemsPerPage,
			})
		} catch(error) {
			AlertModule.setError(error)
			return
		} finally {
			this.buscando = false
		}

		this.notasResumidas = notasPaginadas.content
		this.paginacao.itemsLength = notasPaginadas.totalElements
	}

	atualizarPagina(page) {
		this.paginacao.page += page
		this.buscarVendas()
	}

	mostra(mostra: boolean) {
		this.etapaDaDevolucao = 'escolhendo-nota'
		this.mostrar = mostra
		this.paginacao = {
			page: 0,
			itemsPerPage: 10,
			itemsLength: 0,
		}
		this.filtroNF = null
		this.filtroSerie = null
		this.selecionados = []
		this.buscarVendas()
	}

	confirmar() {
		this.mostrar = false
	}

	cancelar() {
		this.mostrar = false
		this.etapaDaDevolucao = 'escolhendo-nota'
		this.paginacao = {
			page: 0,
			itemsPerPage: 10,
			itemsLength: 0,
		}
		this.notaSelecionada = null
	}

	obterQuantidadeOriginalDoItem(itemNota: ItemNotaFiscal) {
		if (!itemNota) return 0

		const quantidadeNaoDevolvida = new Decimal(itemNota.qcom).toNumber() - itemNota.quantidadeDevolvida
		return quantidadeNaoDevolvida
	}

	estaSelecionado(itemId: string) {
		const item = this.selecionados.find(item => item.id === itemId)
		if (!item) return true
		return false
	}

	validarQuantidade(quantidade: number, itemNota: ItemNotaFiscal) {
		const quantideOriginal = this.obterQuantidadeOriginalDoItem(itemNota)
		if (quantidade > quantideOriginal) return 'Quantidade excedida'
		return true
	}

	obterTotalDoItemDevolucao(item: ItemNotaFiscal) {
		return Number(item.vunCom) * item.quantidadeSaldo
	}

	trocarSelecionarTodos() {
		this.selecionados = this.selecionados.length
			? []
			: (this.selecionados = [...this.itensFormatados])

		if (!this.notaSelecionada) return

		if (this.selecionados.length > 0) {
			this.selecionouTodos = true
			this.selecionados.forEach(item => item.quantidadeSaldo = Number(item.qcom) - item.quantidadeDevolvida)
		} else {
			this.selecionouTodos = false
			this.selecionados.forEach(item => item.quantidadeSaldo = 1)
		}
	}

	async selecionarNotaParaDevolucao(notaSelecionada: CapaDaNota) {
		try {
			this.buscando = true
			this.notaSelecionada = await this.findNotaUseCase.getDetalhes(notaSelecionada.id)
			this.selecionados = []
			this.etapaDaDevolucao = 'escolhendo-itens'
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.buscando = false
		}
	}

	async devolver() {
		if (!this.notaSelecionada) return
		try {
			this.iniciandoDevolucao = true
			await this.iniciarDevolucao.devolverNotaSaida({
				itensADevolver: this.selecionados,
				notaFiscalOrigem: this.notaSelecionada,
			})

			this.cancelar()
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.iniciandoDevolucao = false
		}
	}
}
