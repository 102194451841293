
















import CampoDeCpfOuCnpjOculto from '@/components/ui/CampoDeCpfOuCnpjOculto.vue'
import { Vue, Component, Ref, Watch, PropSync, Prop } from 'vue-property-decorator'
import SeletorDePessoa from '@/components/cliente/SeletorDePessoa.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { obrigatorio } from '@/shareds/regras-de-form'
import { FindClienteUseCase } from '@/usecases'
// import { isVestcasa } from '@/shareds/utils'
import type { Fornecedor } from '@/models'
import { removerFormatacaoDeCnpjOuCpf } from '@/shareds/formatadores'
import SeletorDeFornecedor from '@/components/fornecedor/SeletorDeFornecedor.vue'

@Component({
	components: {
		CampoDeCpfOuCnpjOculto,
		SeletorDePessoa,
		SeletorDeFornecedor,
	},
})
export default class CampoDeFornecedorNaEmissaoDeNota extends Vue {
	@Ref() campoDeCliente!: SeletorDeFornecedor
	@Ref() trocadorDeUtilizarApenasCpf!: { $el: HTMLDivElement }
	@PropSync('value') fornecedor!: Fornecedor
	@Prop({type: Boolean, default: false}) clearable!: boolean

	mostraTrocarUtilizarCpf: boolean | null = null
	findClienteUseCase = new FindClienteUseCase()
	obrigatorio = obrigatorio
	isVestcasa = true
	buscandoCliente = false

	focus() {
		this.campoDeCliente.focus()
	}

	get venda() {
		return VendaModule.emissaoEntradaAtual
	}

	get usuario() {
		return UserLoginStore.usuario
	}

	get utilizandoCampoDeCpf() {
		return this.componenteDeCliente === 'CampoDeCpfOuCnpjOculto'
	}

	get obrigatorioInformarCliente() {
		return this.venda?.tipoDeTransacao === 'Devolução'
	}

	get componenteDeCliente() {
		return this.venda && this.venda.tipoDeTransacao === 'Devolução'  && this.venda.tipoNota === "Saída"
			? 'SeletorDeFornecedor'
			: 'CampoDeCpfOuCnpjOculto'
	}

	get infosCliente() {
		return VendaModule.informacoesDoClienteDaNota
	}

	set infosCliente(informacoes: typeof VendaModule['informacoesDoClienteDaNota']) {
		VendaModule.setInformacoesDoClienteDaNota(informacoes)
	}

	get carregandoOsItensDaVenda() {
		return VendaModule.carregandoItensDaVenda
	}

	@Watch('fornecedor', { immediate: true, deep: true })
	onChangeCliente() {
		this.aplicarFornecedorNaVenda(this.fornecedor)
	}

	async aplicarFornecedorNaVenda(fornecedor: Fornecedor, forcarReconsulta = false) {
		if(!fornecedor) return
		try {
			/*await AlterarPrecosDaVendaPorCliente({
				cliente,
				onAtualizarCarregando: (buscando) => this.buscandoCliente = buscando,
				forcarReconsulta,
				validarLimiteDeProduto: true,
			})*/
			
			// if necessário para evitar disparar evento durante a digitação do CPF

			const cpfOuCnpjFormatado: string = typeof fornecedor !== 'string'
				? fornecedor?.cnpjOuCpf || ''
				: fornecedor

			const venda = VendaModule.emissaoEntradaAtual

			if (venda) {
				const cpfOuCnpj = removerFormatacaoDeCnpjOuCpf(cpfOuCnpjFormatado)
				if (!forcarReconsulta && cpfOuCnpj === venda.cpfDoCliente && VendaModule.informacoesDoCliente) return

				venda.cpfDoCliente = cpfOuCnpj

				VendaModule.setEmissaoEntradaAtual(venda)
			}

		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.buscandoCliente = false
		}
	}

	@Watch('buscandoCliente')
	onChangeBuscandoCliente() {
		this.$emit('update:buscandoCliente', this.buscandoCliente)
	}
	
	@Watch("utilizarApenasCpf")
	onChangeUtilizarApenasCpf() {
		this.mostraTrocarUtilizarCpf = false
		if (!this.venda) return
		this.venda.cpfDoCliente = null
		this.venda.cliente = null
	}

	@Watch('mostraTrocarUtilizarCpf')
	onChangeMostraTrocarUtilizarCpf(mostra: boolean) {
		if (!mostra) {
			this.$nextTick(() => {
				this.focus()
			})
			return
		}
		setTimeout(() => {
			this.trocadorDeUtilizarApenasCpf.$el.focus()
		}, 125)
	}
}

