




























































import type { CapaDaNota, NotaFiscalCompleta } from '@/models' 
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FindCapaDaNotaUseCase } from '@/usecases'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import { deformatarValorDecimal, formatarMoedaStr } from '@/shareds/formatadores'

@Component({
	components: {
	},
})
export default class DialogoDeDetalhesDaNota extends Vue {
	@Prop() nota!: CapaDaNota

	dateTimeToPtBrFormat = dateTimeToPtBrFormat
	formatarMoeda = formatarMoedaStr
	deformatarValorDecimal = deformatarValorDecimal

	notaComItens: NotaFiscalCompleta | null = null

	mostra: boolean | null = null
	carregando = false

	findNotaUseCase = new FindCapaDaNotaUseCase()

	@Watch('mostra')
	onChangeMostra() {
		if (this.mostra) {
			this.buscarNotaComItens()
		}
		this.notaComItens = null
	}

	async buscarNotaComItens() {
		if (!this.nota) return
		try {
			this.carregando = true
			this.notaComItens = await this.findNotaUseCase.getDetalhes(this.nota.id)
		} catch (e) {
			AlertModule.setError(e)
			this.mostra = false
		} finally {
			this.carregando = false
		}
	}

	retornarNota() {
		this.mostra = false
		this.$emit('devolverNota', this.nota)
	}
}
