var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","fullscreen":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[(_vm.etapaDaDevolucao == 'escolhendo-nota')?_c('v-card',[_c('v-card-title',{staticClass:"mr-500"},[_c('h3',[_c('v-icon',{staticClass:"mr-2",on:{"click":_vm.cancelar}},[_vm._v("mdi-arrow-left")]),_vm._v("Selecione a nota que deseja devolver ")],1)]),_c('v-card-text',[_c('v-row',{staticClass:"ml-5"},[_c('v-col',{attrs:{"sm":"2"}},[_c('v-text-field',{attrs:{"label":"Número NF","single-line":"","hide-details":""},model:{value:(_vm.filtroNF),callback:function ($$v) {_vm.filtroNF=$$v},expression:"filtroNF"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-text-field',{attrs:{"label":"Serie","single-line":"","hide-details":""},model:{value:(_vm.filtroSerie),callback:function ($$v) {_vm.filtroSerie=$$v},expression:"filtroSerie"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-btn',{staticClass:"mt-4",on:{"click":_vm.buscarVendas}},[_vm._v(" Buscar ")])],1)],1)],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[(!_vm.buscando)?_c('v-card',{staticClass:"mt-2"},[_c('v-data-table',{attrs:{"headers":_vm.headersVendas,"items":_vm.notasFormatadas,"hideCreate":"","loading":_vm.buscando,"sort-by":"dataHora","sort-desc":[true],"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap justify-center"},[_c('DialogoDeDetalhesDaNota',{attrs:{"nota":item},on:{"devolverNota":_vm.selecionarNotaParaDevolucao},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},Object.assign({}, tooltip, dialog)),[_vm._v(" mdi-eye ")])]}}],null,true)},[_vm._v(" Detalhes da venda ")])]}}],null,true)}),_c('Confirmacao',{attrs:{"width":"300","titulo":"Devolver esta nota?","subtitulo":("<div>" + (item.nnf) + "/" + (item.serie) + "</div>")},on:{"confirmar":function () { return _vm.selecionarNotaParaDevolucao(item); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":""}},Object.assign({}, dialog, tooltip)),[_vm._v(" "+_vm._s('mdi-keyboard-return')+" ")])]}}],null,true)},[_vm._v(" Devolver ")])]}}],null,true)})],1)]}},{key:"footer",fn:function(){return [_c('RodapePersonalizado',{attrs:{"paginacao":_vm.paginacao,"total":_vm.notasFormatadas.length,"loading":_vm.buscando},on:{"update:paginacao":function($event){_vm.paginacao=$event},"navegar":_vm.atualizarPagina}})]},proxy:true}],null,true)})],1):_c('v-card',[_c('AppLoading')],1)],1)],1)],1):_c('v-card',[_c('v-card-title',{staticClass:"mr-500"},[_c('h3',[_c('v-icon',{staticClass:"mr-2",on:{"click":function () { return _vm.etapaDaDevolucao = 'escolhendo-nota'; }}},[_vm._v("mdi-arrow-left")]),_vm._v("Selecione os itens que deseja devolver ")],1)]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersItensDaNota,"items":_vm.itensFormatados,"show-select":"","items-per-page":6,"page":_vm.paginaSelecaoItens,"hide-default-footer":""},on:{"update:page":function($event){_vm.paginaSelecaoItens=$event}},scopedSlots:_vm._u([{key:"item.quantidade",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-text-field',{ref:"checkboxDeSelecionarTodos",staticClass:"quantidade-do-item",staticStyle:{"width":"115px"},attrs:{"disabled":_vm.estaSelecionado(item.id),"type":"number","max":_vm.obterQuantidadeOriginalDoItem(item),"min":"1","rules":[
								_vm.maiorQueZero,
								function (quantidade) { return _vm.validarQuantidade(quantidade, item); } ],"suffix":("/ " + (_vm.obterQuantidadeOriginalDoItem(item)))},model:{value:(item.quantidadeSaldo),callback:function ($$v) {_vm.$set(item, "quantidadeSaldo", $$v)},expression:"item.quantidadeSaldo"}})],1)]}},{key:"item.preco",fn:function(ref){
							var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatarMoeda(_vm.obterTotalDoItemDevolucao(item)))+" ")]}},{key:"header.data-table-select",fn:function(){return [_c('div',[_c('v-simple-checkbox',{ref:"checkboxDeSelecionarTodos",staticClass:"checkbox",attrs:{"data-cy":"selecionar-todos","color":"success","tabindex":"0","value":_vm.selecionados.length === _vm.itensFormatados.length,"indeterminate":!!_vm.selecionados.length &&
									_vm.selecionados.length !== _vm.itensFormatados.length},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.stopPropagation();return _vm.trocarSelecionarTodos.apply(null, arguments)},"click":function($event){$event.stopPropagation();return _vm.trocarSelecionarTodos.apply(null, arguments)}}})],1)]},proxy:true},{key:"item.data-table-select",fn:function(ref){
									var isSelected = ref.isSelected;
									var select = ref.select;
return [_c('div',[_c('v-simple-checkbox',{staticClass:"checkbox",attrs:{"data-cy":"selecionar","color":"success","value":isSelected,"tabindex":"0"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () { return select(!isSelected); }).apply(null, arguments)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.stopPropagation();return (function () { return select(!isSelected); }).apply(null, arguments)}}})],1)]}}],null,true),model:{value:(_vm.selecionados),callback:function ($$v) {_vm.selecionados=$$v},expression:"selecionados"}}),_c('div',{staticClass:"pb-5"},[_c('v-pagination',{attrs:{"circle":"","color":"success","length":Math.ceil(_vm.itensFormatados.length / 6)},model:{value:(_vm.paginaSelecaoItens),callback:function ($$v) {_vm.paginaSelecaoItens=$$v},expression:"paginaSelecaoItens"}})],1),_c('v-card-actions',{staticClass:"flex-star"},[_c('v-btn',{attrs:{"data-cy":"devolver","color":"success","x-large":"","block":"","disabled":_vm.selecionados.length === 0},on:{"click":function($event){return _vm.devolver()}}},[_vm._v(" Devolver Itens ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }